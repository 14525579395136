




















































































































































































































































































































































.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 auto;
  height: 100%;

  .scrolling-table {
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 10px;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0059A3;
      border-radius: 3px;
    }
  }
}
