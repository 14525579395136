





































































































.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 auto;
  height: 100%;

  h2 {
    margin-bottom: 8px;
  }
}
