

























.steps-wrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.steps::v-deep {
  width: 500px;

  .ant-steps-item-title {
    min-width: 135px;
    margin-left: -6px;
  }

  .ant-steps-icon {
    margin-top: -2px;

    svg {
      margin-top: -5px;
    }
  }
}
