





































.button_wrapper {
  text-align: center;
  margin-top: auto;

  > div {
    color: #B1BACB;
    font-size: 14px;
    margin-bottom: 24px;
    cursor: pointer
  }
}
