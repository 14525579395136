









































































































































.preview {
  margin-bottom: 30px;

  max-height: 450px;
  overflow-y: scroll;
  padding-right: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0059A3;
    border-radius: 3px;
  }

  table {
    width: 100%;
    background-color: white;
  }

  table, td {
    border: 1px solid #D5D9E1;
  }

  td {
    padding: 10px;

    &:nth-child(1) {
      width: 50%;
    }
  }
}
